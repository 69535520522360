<template>
  <div class="social-media">
    <div class="tile image-tile">
      <img
        srcset="
          /smartphone-chocolate.png    316w,
          /smartphone-chocolate@2x.png 632w
        "
        sizes="(min-width: 1366px) 25vw, (min-width: 768px) 28vw, 50vw"
        alt=""
        loading="lazy"
        class="image"
      />
    </div>

    <AnimatedMouseTrail class="tile socials-tile">
      <HeartIcon class="socials-tile__cover" />
      <p class="socials-tile__title" v-html="nbsp(t('follow-social-media'))" />
      <Socials type="blue" />
    </AnimatedMouseTrail>
  </div>
</template>

<script lang="ts" setup>
import HeartIcon from '@/assets/icons/heart.svg?component'

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.social-media {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.tile {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
}

.image-tile {
  background: $light-blue-2-default;
}

.image {
  @include fill-space;
  top: auto;
  bottom: 0;
  height: 115%;
  object-fit: contain;
  object-position: 0 0;
}

.socials-tile {
  padding: rem(26px);
  padding-right: rem(9px);
  background: $blue;

  @include media-up(md) {
    padding: rem(31px);
    padding-right: rem(11px);
  }

  @include media-up(lg) {
    padding: rem(41px) rem(36px);
  }

  @include media-up(xl) {
    padding: rem(58px) rem(51px);
  }

  .contrast-mode & {
    background: $contrast-black;
  }

  &__cover {
    @include fill-space;
    pointer-events: none;
    transform: scale(0.7);

    .contrast-mode & {
      display: none;
    }
  }

  &__title {
    @include font-size(
      (
        xs: 18px,
        lg: 32px,
        xl: 46px
      )
    );
    margin-bottom: rem(38px);
    font-weight: 400;
    line-height: 1.12;
    color: $beige;
    letter-spacing: -0.025em;

    @include media-up(lg) {
      line-height: 1.25;
    }

    @include media-up(xl) {
      margin-bottom: rem(48px);
    }
  }
}

.links {
  display: flex;
  gap: rem(9px);

  @include media-up(lg) {
    gap: rem(14px);
  }
}

.link {
  @include center-content;
  width: rem(30px);
  aspect-ratio: 1/1;
  color: $blue;
  background: $beige;
  border-radius: 50%;

  @include media-up(md) {
    width: rem(34px);
  }

  @include media-up(lg) {
    width: rem(38px);
  }

  .contrast-mode & {
    color: $contrast-black;
  }

  &__icon {
    width: 50%;
    height: 100%;

    &--facebook {
      width: 23%;
    }
  }
}
</style>

<template>
  <div class="container" @mousemove="onMouseMove">
    <slot />
    <canvas ref="canvas" class="canvas" />
  </div>
</template>

<script lang="ts" setup>
import { useElementBounding, useRafFn } from '@vueuse/core'

const canvas = ref<HTMLCanvasElement | null>(null)

const { top, left, width, height } = useElementBounding(canvas)

let ctx: CanvasRenderingContext2D | null = null
let mouseX = 0
let mouseY = 0
let trailX = 0
let trailY = 0

const onMouseMove = (e: MouseEvent) => {
  mouseX = e.x - left.value
  mouseY = e.y - top.value
}

watch([width, height], () => {
  if (!canvas.value) return
  ctx = canvas.value.getContext('2d')
  if (!ctx) return

  canvas.value.width = width.value
  canvas.value.height = height.value

  ctx.lineWidth = 5
  ctx.lineCap = 'round'
  ctx.lineJoin = 'round'
  ctx.strokeStyle = '#fcf6e7'
})

const trailBuffer: [number, number][] = []

useRafFn(({ delta, timestamp }) => {
  if (!canvas.value || !ctx || (mouseX === 0 && mouseY === 0)) return

  ctx.globalAlpha = 1 / delta + Math.random() * 0.1
  ctx.globalCompositeOperation = 'destination-out'
  ctx.fillRect(0, 0, canvas.value.width, canvas.value.height)

  const trailSpeed = 0.02 * delta

  trailX = trailX + trailSpeed * (mouseX - trailX)
  trailY = trailY + trailSpeed * (mouseY - trailY)

  trailX += Math.cos(((timestamp / 2) * Math.PI) / 180) / 4
  trailY += Math.sin(((timestamp / 2) * Math.PI) / 180) / 4

  trailBuffer.push([trailX, trailY])
  if (trailBuffer.length > 10) trailBuffer.shift()

  ctx.globalAlpha = 0.3
  ctx.globalCompositeOperation = 'source-over'

  ctx.beginPath()
  trailBuffer.forEach(([x, y]) => ctx?.lineTo(x, y))
  ctx.lineTo(trailX, trailY)
  ctx.stroke()
})
</script>

<style lang="scss" scoped>
.container {
  @include size(100%);
  position: relative;

  & * {
    @include fill-space;
  }
}

.canvas {
  pointer-events: none;
}
</style>
